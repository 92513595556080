.img-responsive {
    display: block;
    width: 100%;
    height: auto;
}

.main-container {
    display: block;
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.custom-top-bar  {
    display: block;
    margin: 0px;
    padding: 0px;
    height:80px;

    ul.menu {
        list-style:none;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        li {
            border-bottom: 6px solid black;
            padding: 10px 20px;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80px;

            &.menu-item-logo {
                flex-grow: 0;
            }

            &.menu-item-orange {
                flex-grow: 0;
                border-color:#ff6f00;
            }
            &.menu-item-yellow {
                flex-grow: 0;
                border-color:#edb700;
            }
            &.menu-item-green {
                flex-grow: 0;
                border-color:#21d472;
            }
            &.menu-item-dark-green {
                border-color:#0aa951;
            }
            &.menu-item-blue {
                border-color:#008ec3;
                background-color:#76fcff;
                font-size: 17px;
                font-family: 'roboto-condensed';
                letter-spacing: 3px;
            }
            &.menu-item-dark-blue {
                border-color:#203a6e;
            }

            a {
                font-family: 'roboto-condensed';
                letter-spacing: 3px;
            }
            button {}

            .icon-marker {
                display: flex;
                margin-right: 25px;

                &:last-child {
                    margin-right: 0px;
                }

                .icon {
                    height: 27px;
                    margin-right: 10px;

                    img {
                        height: 100%;
                        width: auto;
                    }
                }

                .data {
                    display: flex;
                    flex-direction: column;
                    font-size: 16px;
                    line-height: 15px;

                    span {
                        font-size: 9px;
                        font-weight: bold;
                    }
                }
            }

            &.menu-item-slider-button {
                flex-grow: 0;
                background-color:#e2dacf; 
                padding:0px;                

                a {
                    background-color: #e2dacf;
                    width: 100%;
                    padding: 10px 30px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.2s ease-out;

                    img {
                    border: 0;
                    height: 16px;
                    width: auto;
                    margin: 0px 0px 0px 10px;                        
                    }

                    &:hover {
                        padding: 10px 35px;
                        background-color: #bfbab3;
                    }
                }


            }

        }
    }

    .main-logo {
    display: block;
    width: 100%;
    max-width:250px;
    max-width: 290px;
    padding: 0px 20px;
    }

}

a.button.rounded,
button[type=button].rounded {
border-radius:          0.4rem;
}

a.button,
button[type=button] {
letter-spacing: 3px;
}

a.button.lift, 
button[type=button].lift {
margin-top:0px;
transition:         margin-top .1s ease-out;
}

a.button.lift:hover, 
button[type=button].lift:hover {
margin-top:-3px;
}

.custom-sidebar {

    position:absolute;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    top:0px;
    left: 0px;
    overflow: hidden;    

    .bg {
        position:absolute;
        width: 800px;
        min-width: 100vw;
        height: 100vh;
        z-index: 2;
        top:0px;
        right: 0px;

        background: rgba(0, 0, 0, 0.5);
        background: linear-gradient(90deg,rgba(0,0,0,0) 0,rgba(0,0,0,.5) 30%,#000 100%);
    }

    .color-bar {
        display: none !important;
    }    

    a.btn-close {
        position: absolute;
        top: 0px;
        left: -160px;
        font-family: 'roboto-condensed';
        letter-spacing: 3px;
        font-size: 16px;
        text-transform: uppercase;
        height: 74px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: all 0.2s ease-out;
        z-index: 2;

        .mini-logo {
            display: none;
        }        

        .btn-close-btn {
        display: inline-flex;
        background-color: #76fcff;
        padding: 10px 30px;
        width: 160px;
        align-items: center;
        height: 100%;  
        transition: all 0.2s ease-out;


            &:hover {
                padding: 10px 35px;
                background-color: #12dce1;
            }            

        }

        img {
        border: 0;
        height: 16px;
        width: auto;
        margin: 0px 0px 0px 15px;                        
        }
    }

    div.content {
        position: absolute;
        top: 0px;
        height: 100vh;
        width: 100%;
        max-width: 400px;
        background-color: black;
        right: 0px;
        z-index: 3;
        // border-left:1px solid #ccc;
        //box-shadow: 20px 0px 140px 90px rgb(0 0 0 / 72%);

        ul.accordion {
            height: 100vh;
            overflow-y: auto;
            background-color:black;

            li.accordion-item {
                border-bottom: 3px solid black;
            }

            a.accordion-title {
                font-family: 'roboto-condensed';
                letter-spacing: 3px;
                font-size: 16px;
                text-transform: uppercase;

                height: 74px;
                background-color: #e2dacf;
                width: 100%;
                padding: 10px 30px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                transition: all 0.2s ease-out;

                img {
                border: 0;
                height: 16px;
                width: auto;
                margin: 0px 15px 0px 0px;                        
                }

                &:hover {
                    padding: 10px 35px;
                    background-color: #bfbab3;
                }   

                &:before {
                    content:' ';
                    background:url('../img/icon-arrow-down.svg');
                    background-repeat:no-repeat;
                    background-size:15px 15px;
                    background-position:center center;
                    width: 20px;
                    height: 20px;
                    margin-top: -10px;
                    transition: all 0.1s ease-out;
                    transform: rotate(-90deg);
                    right: 30px;
                }    
            }

            li.accordion-item.is-active a.accordion-title:before {
                transform: rotate(0deg);
            }


            .accordion-content {
                margin: 0px;
                padding: 0px;

                .buttons-list {
                    list-style:none;
                    display: flex;
                    flex-direction: column;
                    padding:0px 0px 0px 0px;
                    margin: 0px;

                    li {
                        padding: 0px;
                        margin: 0px;

                        a.button {
                            margin: 0px;
                            width: 100%;
                            text-align: left;
                            padding: 0px 28px;
                            background-color:white;
                            color:black;                        

                            span {
                                position: relative;
                                display: block;
                                padding: 15px 5px;
                                border-bottom: 1px solid black;

                                &:after {
                                    position:absolute;
                                    content:' ';
                                    // background-image:url('../img/icon-arrow-down.svg');
                                    background-repeat:no-repeat;
                                    background-size:12px 12px;
                                    background-position:center center;
                                    width: 20px;
                                    height: 20px;
                                    top:50%;
                                    right: 0px;
                                    margin-top: -10px;
                                    transition: all 0.1s ease-out;
                                    transform: rotate(-90deg);
                                }                                
                            }
                        }

                        &:last-child {
                            a.button, 
                            a.button span {
                                border:0px;
                            }
                        }

                        &.active {
                            a.button {
                                background-color:#76fcff;
                            }
                        }

                        &:hover {
                            a.button {  
                                background-color:black; 
                                color:white;

                                span {
                                    &:after {
                                        background-image:url('../img/icon-arrow-down-white.svg');
                                    }
                                }
                            }
                        }

                    }

                    &.options-list li:first-child {
                        a.btn-option {
                            border-bottom:1px solid black;
                            padding-top:10px ;
                            padding-bottom:5px;
                            margin-bottom:5px;
                        }
                    }

                }
            }

            .item-select {
                padding: 10px 28px 0px; 

                span {
                    font-family: 'roboto';
                    font-weight: bold;
                    font-size: 11px;
                    line-height: 11px;
                }

                select.custom-select {
                    display: block;
                    position:relative;
                    appearance: none;
                    outline: none;
                    padding: 13px 16px;
                    letter-spacing: 3px;
                    font-family: "roboto-condensed";
                    font-size: 0.9rem;
                    line-height: 0.9rem;
                    border: 1px solid #000!important;
                    color: #000!important;
                    border-radius: 0.4rem;
                    height: 42px;
                    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='%23000000' stroke-width='2' stroke-linecap='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e");
                    background-repeat: no-repeat;
                    background-position: right 10px center;
                    background-size: 19px 19px;
                    margin: 0px 0px 0px 0px;
                    background-color: #fff;

                    &.custom-select-black {
                        border:1px solid black !important;
                        color:white !important;

                        background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e");
                        background-repeat: no-repeat;
                        background-position: right 10px center;
                        background-size: 19px 19px;
                        background-color:black;                        
                    }                   
                }

                a.button.button-select {
                    padding: 13px 16px !important;
                    background-color:black !important;
                    color:white !important;
                    position:relative;

                    &:after {
                        position:absolute;
                        content:' ';
                        background-image:url('../img/icon-arrow-down-white.svg');
                        background-repeat:no-repeat;
                        background-size:12px 12px;
                        background-position:center center;
                        width: 20px;
                        height: 20px;
                        top:50%;
                        right: 10px;
                        margin-top: -10px;
                        transition: all 0.1s ease-out;
                    }   

                    &.button-select-white {
                        border:1px solid black !important;
                        background-color:white !important;
                        color:black !important;

                        &:after {
                        background-image:url('../img/icon-arrow-down.svg');
                        }
                    }                
                }
            }
        }
    }

    .btn-option {
        display: flex;
        margin: 5px 28px;
        align-items:center;
        justify-content: space-between;
        
        color: #b7b7b7;
        fill: #b7b7b7;

        .selection-icon {
            display: block;
            flex-grow: 0;
            width: 15px;
            height: 15px;
            margin: 0px 6px 0px 0px;
            display: flex;
            align-items: center;
            pointer-events: none;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        .label {
            flex-grow: 1;
            margin: 0px 6px 0px 0px; 
            background-color:transparent;
            color: inherit;
            font-family: 'roboto' !important;
            font-size: 12px;
            line-height: 13px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            pointer-events: none;
        }

        .activity {
            flex-grow: 0;
            margin: 0px 0px 0px 10px;
            font-family: 'roboto';
            position: relative;
            display: block;
            overflow: hidden;
            border-radius:3px;
            pointer-events: none;

            .activity-label {
                position: relative;
                color: white !important;
                text-align: center;
                z-index: 2;
                font-size: 10px;
                font-family: 'roboto-condensed' !important;
                padding: 2px 5px !important;
                display: block;
                width: 50px;
                font-weight: bold !important;
                }

            .activity-bg {
                display: block;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                z-index: 0; 

                svg {
                    width: 100%;
                }
            }           
        }

        .trend {
            flex-grow: 0;
            margin: 0px 0px 0px 0px; 
            font-family: 'roboto';
            font-size: 10px;
            display: flex;
            pointer-events: none;

            .trend-icon {
                position:relative;
                width: 15px;
                height: 15px;

                svg {
                    // width: 100%;
                    // height: auto;
                }
            }

            .trend-label {
                display: block;
                font-weight: bold;
                margin: 0px 0px 0px 5px;
                width: 31px;
            }
        }


        &.unchecked {
            color: #e9e9e9 !important;
            fill: #e9e9e9 !important;

            .selection-icon {
                svg {
                    circle {
                        display: none  !important;
                    }
                }
            }
        }
    }
}

.body-container {
    width: 100vw;
    height: calc(100vh - 120px);
    background-color:black;
}

.body-container .container-treemap{
    width: 100vw;
    height: 100%;
}

.custom-secondary-bar {
    background-color:black;
    width: 100vw;
    flex-grow: 0;
    height: 40px;
    position: relative;

    .custom-secondary-bar-content {
        position:absolute;
        width: 100vw;
        height: 34px;
        overflow: hidden;
        top: 0px;
        left: 0px;
        background-color:black;
        transition: all 0.2s ease-out;
        z-index: 3;
    }

    .btn-options {
        position:absolute;
        width: auto;
            height: 40px;
        overflow: hidden;
        top: 0px;
        right: 0px;
        background-color:black;
        transition: all 0.2s ease-out;
        z-index: 4;
        color:white;
        font-family: 'roboto-condensed';
        letter-spacing: 3px;
        font-size: 15px;
        line-height: 15px;

        text-transform: uppercase;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img {
        border: 0;
        height: 16px;
        width: auto;
        margin: 0px 0px 0px 15px;                        
        }

        &:hover {
            padding: 10px 17px;
        }         
    }

    &:hover .custom-secondary-bar-content {
        height: 85px;
    }

}

.horizontal-options-list {
    list-style:none;
    display: flex;
    flex-direction: row;
    padding:0px 15px 0px 15px;
    margin: 0px;
    height: 100%;

    li {
        padding: 0px;
        margin: 0px;

        .btn-option-row {
            display: flex;
            margin: 0px 0px 5px 0px !important;
            align-items: center;
        }

        .btn-option {
            display: flex;
            flex-direction:column;
            margin: 3px 5px;
            align-items: end;
            justify-content: end;
            background-color: rgba(255,255,255,0);
            transition: all 0.2s ease-out;
            padding:11px 6px 12px 6px;

            color: #b7b7b7;
            fill: #b7b7b7;

            &:hover {
            background-color: rgba(255,255,255,0.15);
            }

            .selection-icon {
                display: block;
                flex-grow: 0;
                width: 15px;
                height: 15px;
                margin: 0px 6px 0px 0px;
                display: flex;
                align-items: center;
                pointer-events: none;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }

            .label {
                flex-grow: 1;
                margin: 0px 6px 0px 0px; 
                padding: 0px;
                background-color:transparent;
                color: inherit;
                font-family: 'roboto' !important;
                letter-spacing: 1px;
                font-size: 11px;
                line-height: 11px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                pointer-events: none;
            }

            .activity {
                flex-grow: 0;
                margin: 0px 15px 0px 0px;
                font-family: 'roboto';
                position: relative;
                display: block;
                overflow: hidden;
                border-radius:3px;
                pointer-events: none;

                .activity-label {
                    position: relative;
                    color: black;
                    width: 100%;
                    text-align: center;
                    z-index: 2;
                    font-size: 10px;
                    font-family: 'roboto-condensed' !important;
                    padding: 2px 8px;
                    display: block;
                    width: auto;
                    letter-spacing: 1px !important;
                    font-weight: bold !important;
                    }

                .activity-bg {
                    display: block;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    z-index: 0; 

                    svg {
                        width: 100%;
                    }
                }           
            }

            .trend {
                flex-grow: 0;
                margin: 0px 0px 0px 0px; 
                font-family: 'roboto';
                font-size: 10px;
                display: flex;
                pointer-events: none;

                .trend-icon {
                    position:relative;
                    width: 15px;
                    height: 15px;

                    svg {
                        // width: 100%;
                        // height: auto;
                    }
                }

                .trend-label {
                    display: block;
                    font-weight: bold;
                    margin: 0px 0px 0px 5px;
                    width: auto;
                }
            }


            &.unchecked {
                color: #b7b7b7 !important;
                fill: #b7b7b7 !important;

                .selection-icon {
                    svg {
                        circle {
                            display: none  !important;
                        }
                    }
                }

                .trend, .activity {
                    display: none;
                }
            }
        }

    }

}

.color-bar {
    display: none;
    height: 5px;

    img {
        width: 100%;
        height: 5px;
        display: block;
    }
}

.menu-item-slider-button {
    a span {
        display: inline;
    }
}


@media (max-width: 480px) { 
    .custom-top-bar ul.menu li {
        //width: 180px;
        padding: 0px;
        margin: 0px;
    }
}

@media (max-width: 639px) { 
    .show-for-medium {
        display: none !important;
    }

    .custom-sidebar { 
        div.content {
            max-width: 100%;
        }
    }

    .custom-sidebar .color-bar {
        display: block !important;
    }    

    .custom-sidebar a.btn-close {
        position: relative;
        left: 0px;
        background-color: white;
        display: flex;
        justify-content: space-between;   
        
        .mini-logo {
            display: flex;
            height: 100%;
            padding:10px;

            img {
                width: 50px;
                height: 50px;
                padding: 5px 0px 0px 10px;
            }
        }     
    }
}

// @media (max-width: 959px) { 
@media (max-width: 1023px) { 
    .show-for-large {
        display: none !important;
    }

    .custom-top-bar ul.menu,
    .custom-top-bar ul.menu li {
        height:75px;
        overflow: hidden;
    }

    .body-container {
        height: calc(100vh - 80px);
    }

    .color-bar {
        display: block !important;
    }

    .custom-top-bar ul.menu li{
        border:0px !important;
    }

    .menu-item-orange.menu-item-logo {
       a { 
        max-width: 229px; 
        padding-right:0px;
       }
    }

    .menu-item-slider-button {
        
        img {
            margin: 0 0 0 0px;
        }
        a {
            background-color:white !important;
            padding: 10px 20px  !important;

            span {
                display: none;
            }
        }
    }

}

@media (max-width: 1199px) { 
    .show-for-xlarge {
        display: none !important;
    }
}

@media (max-width: 1439px) { 
    .show-for-xxlarge {
        display: none !important;
    }
}

.bar-item .icon-svg-white img {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(156deg) brightness(102%) contrast(101%);
}

li.accordion-item.bar-item {
    border-bottom: 6px solid #000;
    padding: 10px 20px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 80px;
}


li.accordion-item.bar-item .icon-marker {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 10px;
    margin-left: 10px;
    color: white;

    .data {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        font-size: 16px;
        line-height: 15px;

        span {
            font-size: 9px;
            font-weight: 700;
        }
    }

    .icon {
        height: 27px;
        margin-right: 10px;
        outline: 0;

        img {
            height: 100%;
            width: auto;
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(156deg) brightness(102%) contrast(101%);
        }
    }
}

li.menu-item-blue {
    border-color: #008ec3;
    background-color: #76fcff !important;
    font-size: 17px;
    font-family: roboto-condensed;
    letter-spacing: 3px;
}

.menu-item-white {
    background-color: #ffffff !important;
     span {
        background-color: #ffffff !important;
        color: #000000 !important;
     }
}

.separation-line{
    //border-top: 2px solid #b7b7b7;
    border-top: 1px solid #000000;
    padding-top: 5px;
    padding-bottom: 0px;
    margin-bottom: 5px;
}

a.button.button-black {
    margin: 0px;
    width: 100%;
    text-align: left;
    padding: 13px 16px !important;
    background-color: black !important;
    color: white !important;
    position: relative;
}

.custom-sidebar div.content ul.accordion .view-option-item.inner-button {
    padding: 0px;

    a.button-refresh {
        font-family: roboto;
        font-size: 13px;
        line-height: 13px;
        white-space: nowrap;
    }
}


a.button.button-black:after {
    position: absolute;
    content: ' ';
    background-image: url(../img/icon-arrow-down-white.svg);
    transform: rotate(-90deg);
    background-repeat: no-repeat;
    background-size: 12px 12px;
    background-position: center center;
    width: 20px;
    height: 20px;
    top: 50%;
    right: 10px;
    margin-top: -10px;
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
}



.view-option-list {
    margin-top: 0px !important;

    input {
        font-family: roboto;
        font-weight: bold;
        font-size: 13px;
        line-height: 13px;
        height: 2rem !important;
        border-radius: 5px;
        margin-bottom: 0px;
        border: 1px solid #000000;
        text-align: right;
    }

    .view-option-item {
        margin: 15px 28px;

        .label {
            padding: 0.33333rem 0rem;
        }
    }
}

li.view-option-list {
    padding: 0;
    margin: 0;
    list-style: none;

    .label {
        display: inline-block;
        padding: 0.33333rem 0.5rem;
        border-radius: 0;
        font-size: .8rem;
        line-height: 1;
        white-space: nowrap;
        cursor: default;
        background: #000;
        color: #fefefe;

        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        margin: 0 6px 0 0;
        background-color: #fff;
        color: inherit;
        font-family: roboto;
        font-size: 13px;
        line-height: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        pointer-events: none;
    }
}

.custom-sidebar .btn-option {
    display: flex;
    margin: 2px 28px;
    align-items: center;
    justify-content: space-between;
    color: #b7b7b7;
    fill: #b7b7b7;
    flex-direction: column;
    transition: all 0.3s ease-out;
    background-color:white;
    padding: 3px 0px;    

    &:hover {
        background-color:#f3f3f3;
    }

    span.hr {
        display: block;
        width: 100%;
        height:3px;
        border-bottom:1px solid #dbdbdb;
    }

    .btn-option-row {
        display: flex;
        margin: 0px 0px 2px 0px !important;
        align-items: center;
        flex-direction: row;
        width: 100%;        
    }

}

a.menu-view-icon.active, a.menu-view-icon:active {
    fill:#000000;
    border-color: #000000;

    span.selection-icon {
        height: 32px;
        width: 32px;
        display: block;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &.grid-icon {
        padding: 0px;
        height: 32px;
        //border: 1px solid #000000;
        border-radius: 5px;
    }

    &.list-icon {
        padding: 0px;
        height: 32px;
        width: 32px;
        //border: 1px solid #878787;
        border-radius: 5px;
    }
}

.text-right {
    text-align: right !important;
}

a.menu-view-icon {
    fill:#878787;
    display: inline-block;
    color: #ffffff;

    &.grid-icon, &.list-icon,
    &.twitter-icon, &.time-icon
     {
        padding: 0px;
        height: 32px;
        //border: 1px solid #878787;
        border-radius: 5px;
        
        .view-mode-icon{
            height: 32px;
            width: 32px;
            display: block;

            svg {
                width: 100%;
                height: 100%;
            }

        }
    }

    &.twitter-icon {
        margin-right: 5px;
    }
}

.bg-black {
    background-color: #000000 !important;
    .label {
        color: #ffffff !important;
    }
}

.accordion-item.footer-black .menu-footer-content {
    display: block;
    padding: 20px 28px 20px;

    .label {
        background-color: #000000;
        color: #e4dacf;
        font-size: 10px;
        font-family: 'roboto' !important;
        font-weight: 900 !important;
        padding: 0px;
        letter-spacing:1px;
        line-height: 15px;
    }

    ul {
        margin-left: 0px !important;

        li a.button-black {
            padding: 0rem;
            display: block;
            position: relative;

            .label {
                background-color: #000000;
                color:#e4dacf;
                pointer-events: none;
            }
        }

        li a.button-black:hover, li a.button-black:focus, li a.button-black:active, li a.button-black.active {
            padding: 0rem;


            .label {
                background-color: #000000;
                color: #00fdff;
            }
        }
    }

}

.smaller {
    font-size: 9px !important;
}

.footer-border-top-bottom {
    border-top: 1px solid #e4dacf !important;
    border-bottom: 1px solid #e4dacf !important;
    margin: 20px 0px 10px 0px !important;
    padding: 5px 0px !important;
}

.accordion-item.footer-black .menu-footer-content ul li a.button-black:after {
    
}

.accordion-item.footer-black .menu-footer-content ul li a:hover.button-black:after, .accordion-item.footer-black .menu-footer-content ul li a:focus.button-black:after, .accordion-item.footer-black .menu-footer-content ul li a:active.button-black:after, .accordion-item.footer-black .menu-footer-content ul li a.active.button-black:after {
    position: absolute;
    content: ' ';
    background-image: url(../img/icon-arrow-down-white.svg);
    transform: rotate(-90deg);
    background-repeat: no-repeat;
    background-size: 12px 12px;
    background-position: center center;
    width: 20px;
    height: 20px;
    top: 45%;
    right: 3px;
    margin-top: -10px;
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
    filter: invert(0%) sepia(71%) saturate(1775%) hue-rotate(124deg) brightness(103%) contrast(106%);
}


///// TREEMAP ////////
.highcharts-container { 
    .highcharts-root {
        .highcharts-data-labels { 
            g.highcharts-label,
            g.highcharts-label text, 
            g.highcharts-label tspan {
                font-family: 'roboto-condensed';
                //fill: black !important;
                // outline-color: none !important;
                // stroke: none !important;
                letter-spacing: 1px;
                stroke-width:0px !important;
                word-break: break-all !important;
            }
        }
    }

    .highcharts-exporting-group { display: none !important; }

    .highcharts-drilldown-data-label, 
    .highcharts-drilldown-data-label text,
    .highcharts-drilldown-data-label tspan {
        text-decoration: none !important;
        fill: black !important;
        stroke-width:0px !important;
        word-break: break-all !important;
    }

    rect.highcharts-point.highcharts-internal-node.highcharts-drilldown-point {
        stroke: #fff !important;
        stroke-width: 1px !important;
    }

    .highcharts-point {
        stroke: #000 !important;
        stroke-width: 1px !important;
      
      &:hover {
          stroke: #FFF !important;
          stroke-width: 6px !important; 
      }
    }

    .tooltip {
      font-family: open-sans;
      font-weight: 400;
      color: #fff;
    }

    .tooltip-body {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 12px;
      vertical-align: center;
      padding: 10px !important;
    }

    .tooltip-header {
  font-family: open-sans;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  display: block;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 10px !important;
    }

    span.stats {
        margin:5px 0px 0px;
        padding:5px 0px 0px;
        font-size:9px;
        line-height: 13px;
        border-top:1px solid #ccc;
        display: block;
    }

    span.hr {
        display: block;
        width: 100%;
        height:0px;
        margin:5px 0px 5px;
        border:1px solid #ccc;
    }

    .tooltip-icon {
display: inline-block;
width: 12px;
height: 12px;
background-repeat: no-repeat;
background-position: center center;
background-size: 12px 12px;
padding-left: 10px !important;
padding-right: 10px !important;

        &.tooltip-icon-twitter {
            background-image: url("/assets/img/icon-twitter-white.svg");

        }

        &.tooltip-icon-trends {
            background-image: url("/assets/img/icon-stats-white.svg");

        }

        &.tooltip-icon-time {
            background-image: url("/assets/img/icon-time-white.svg");

        }
    }

}


div.info-window {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    background-color: white;
    z-index: 20;
    overflow: hidden;
    margin: 0px;
    padding: 0px;

    .container {
        display: flex;
        flex-direction: column;
        margin: 0px;
        padding:0px;
        width: 100vw;
        height: 100vh;
    }

    .header {
        display: flex;    
        flex-grow: 0;  
        align-items: center;  

        a.btn-close {
            position: relative;
            font-family: 'roboto-condensed';
            letter-spacing: 3px;
            font-size: 16px;
            text-transform: uppercase;
            width: auto;
            height: 64px;
            background-color: #76fcff;
            padding: 10px 15px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            transition: all 0.2s ease-out;
            z-index: 2;
            flex-grow: 0;

            img {
            border: 0;
            height: 16px;
            width: auto;
            margin: 0px 15px 0px 0px;                        
            }

            &:hover {
                padding: 10px 17px;
                background-color: #12dce1;
            }            
        }

        div.header-title {
            padding: 15px 20px;
            font-family: 'roboto-condensed';
            letter-spacing: 3px;
            font-size: 16px;
            line-height: 16px;
            white-space: nowrap;
            color: white;
            background-color:black;
            flex-grow: 1;
            display: flex;
            height: 64px;
            align-items:center;
            text-transform: uppercase;
        }
    }

    div.content {
        position: relative;
        flex-grow: 1;
        overflow: auto;
        padding:20px;
    }

}

.content-faq {
    max-width: 1200px;
    margin: auto;
    margin-top: 20px;

    .pr-30 {
        padding-right: 29px;
    }

    .grid-x .cell:first-child {
        border-right: 1px solid gray;
    }

    .faq-img {
        width: 100%;
        max-width: 350px;
    }

    .mb-20 {
        margin-bottom: 20px;
    }

    .cell.gutter-large {
        padding-right: 40px;
        padding-left: 40px;
    }

    .aq {
        margin-bottom: 50px;
    }

    .question {
        font-family: 'roboto';
        font-weight: bold;
        font-size: 17px;
        line-height: 22px;
    }
    .answer {
        font-family: 'roboto-condensed';
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
    }

    .inline-icon {
        line-height: 1px;
        margin-bottom: -2px;
    }

}

@media (max-width: 1024px) { 
    .content-faq .cell.gutter-large {
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media (max-width: 639px) { 
    .content-faq .cell.gutter-large {
        padding-right: 20px;
        padding-left: 20px;
    }

    .content-faq div.grid-x .cell:first-child {
        border-right: none;
    }

    .content-faq .aq {
        margin-bottom: 50px;
    }

    .content-faq .pr-30 {
        padding-right: 15px;
    }

}

@media (max-width: 480px) { 
    .content-faq .cell.gutter-large {
        padding-right: 5px;
        padding-left: 5px;
    }

    .content-faq .aq {
        margin-bottom: 30px;
    }
}

img.img-fluid {
    width: 100%;
    height: auto;
}
